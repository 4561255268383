import React from "react";
import {Role} from "./Auth/BackOfficeUserRole";

export type BillingSenderType = {
    id: number;
    name: string;
    externalClientId: string;
    billableFrom: string;
    taxRate: string;
    cachedRemainingCredits: number;
    cachedEstimatedEnd: string;
};

type StatusResponseType = {
    userId: number;
    email: string;
    firstName: string;
    extraSettings: {
        roles: Role[],
    };
};

type AppContextType = {
    billingSenders: BillingSenderType[];
    currentUser: StatusResponseType;
};

export const AppContext = React.createContext<Partial<AppContextType>>({});
