import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {fetchDocuments, startDocumentsDownload} from '../services';

import AbstractItemsTableWithLoader from '../Tables/AbstractItemsTableWithLoader';
import {ModalContext} from "../Modal/ModalProvider";

const DocumentsTable = ({name, itemsPerPage, searchParams, hideFilters, hideFiltersIfEmpty}) => {
    const {showModal} = useContext(ModalContext);

    const fetchFunction = (searchParams) => {
        return fetchDocuments(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => ({
                ...item,
                id: <Link to={`/document?id=${encodeURIComponent(item.id)}`}>{item.id}</Link>,
            })),
        }));
    };

    const tableColumns = {
        id: {
            name: 'Document Id',
            sort: true
        },
        receivedDate: {
            name: 'Received date',
            style: {
                width: '190px'
            },
            sort: true
        },
        amount: {
            name: 'Amount',
            style: {
                width: '105px'
            },
            sort: true
        },
        reason: 'Reason',
        type: {
            name: 'Type',
            style: {
                width: '140px'
            },
            sort: true
        },
        beneficiary: {
            name: 'Beneficiary',
            sort: true
        },
        beneficiaryIban: {
            name: 'Beneficiary IBAN',
            style: {
                width: '215px',
            },
            sort: true
        },
        payment_status: {
            name: 'Payment status',
            style: {
                width: '165px'
            },
            sort: true
        },
        payment_method: {
            name: 'Payment method',
            style: {
                width: '165px'
            }
        },
        channel: {
            name: 'Channel',
            style: {
                width: '95px'
            }
        },
    };

    const namedSearchParameters = {
        id: {
            type: 'text',
            name: 'Document Id',
            operators: ['eq', 'contains'],
        },
        receivedDate: {
            type: 'date',
            name: 'Received date',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        amount: {
            type: 'text',
            name: 'Amount',
            operators: ['eq'],
        },
        reason: {
            type: 'text',
            name: 'Reason',
            operators: ['eq', 'contains'],
        },
        documentType: {
            type: 'text',
            name: 'Document type',
            operators: ['eq'],
        },
        beneficiary: {
            type: 'text',
            name: 'Beneficiary',
            operators: ['eq', 'contains'],
        },
        beneficiaryIban: {
            type: 'text',
            name: 'Beneficiary IBAN',
            operators: ['eq', 'contains'],
        },
        paymentStatus: {
            type: 'select',
            name: 'Payment Status',
            options: ['paid', 'unpaid', 'pending', 'rejected', 'revoked', 'marked', 'invited', 'partially_refunded', 'refunded', 'scheduled', 'reserved', 'released', 'canceled'],
            operators: ['eq'],
            multiple: true,
        },
        channel: {
            type: 'select',
            name: 'Channel',
            optionsFetchFromStateProperty: 'channels',
            operators: ['eq'],
        },
        customerId: {
            type: 'text',
            name: 'Contract Number',
            operators: ['eq', 'contains'],
        },
        agreementUuid: {
            type: 'text',
            name: 'Agreement UUID',
            operators: ['eq', 'contains'],
        },
        recipientEmail: {
            type: 'text',
            name: 'Recipient Email',
            operators: ['eq', 'contains'],
        },
    };

    return <AbstractItemsTableWithLoader
        name={name}
        itemsPerPage={itemsPerPage}
        tableColumns={tableColumns}
        namedSearchParameters={namedSearchParameters}
        fetchFunction={fetchFunction}
        searchParams={searchParams}
        hideFilters={hideFilters}
        hideFiltersIfEmpty={hideFiltersIfEmpty}
        downloadModalFunc={startDocumentsDownload}
        downloadModalDomainObjectName="documents"
        showModal={showModal}
        minWidth={1700}
    />;
};

export default DocumentsTable;
