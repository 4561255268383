import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import queryString from "query-string";

import Loader from '../Elements/Loader';
import Form from '../Elements/Form';
import Table from '../Elements/Table/Table';
import Pager from '../Elements/Pager';
import {Button} from 'reactstrap';

import {DesktopDownloadIcon, IssueOpenedIcon} from 'react-octicons';

import {Row, Col} from 'reactstrap';
import {withRefresh} from "../Elements/Fetch";
import PomTooltip from "../Elements/PomTooltip";
import isArray from "isarray";
import SimplePager from "../Elements/SimplePager";
import Can from "../Auth/Can";

class AbstractItemsTableWithLoader extends Component {

    _getSearchParameters() {
        return {
            ...Object.keys(this.props.namedSearchParameters).reduce(
                (accumulator, currentValue) => {
                    accumulator[currentValue] = '';
                    accumulator[currentValue + '_op'] = this.props.namedSearchParameters[currentValue].operators[0];
                    if (this.props.namedSearchParameters[currentValue].operators.includes('between')) {
                        accumulator[currentValue + '_to'] = '';
                    }
                    return accumulator;
                },
                {}
            ),
            page: 0,
        };
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            items: [],
            nbPages: undefined,
            nbPagesLimitReached: false,
            nbItems: undefined,
            hasMore: undefined,
            search: this._getSearchParameters(),
            query: null,
            queryParsed: {},
            isCommunicatingWithServer: true,
            error: '',
            itemKeysChecked: [],
            checkButtonActive: true,
            checkButtonLoading: -1,

            //This is copied from Fetch to have same refresh functionality TODO refactor this component to use Fetch
            refreshVersion: props.refreshMap[props.refreshKey], //Keep track of current 'version'. The refreshContainer will increase this number when we need to refresh
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);

        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleCheckButton = this.handleCheckButton.bind(this);

        this.refresh = this.refresh.bind(this);

        this.goNextPage = this.goNextPage.bind(this);
        this.goPreviousPage = this.goPreviousPage.bind(this);
        this.goPage = this.goPage.bind(this);

        this.handleDownloadModal = this.handleDownloadModal.bind(this);
    }

    componentDidMount() {
        this._fetchStateFromUrl();
    }

    componentDidUpdate(prevProps, prevState) {
        // It's a technical decision to not give visual feedback when a user pushes state to url without changes
        this._fetchStateFromUrl();

        //only fetch items from server when known parameters are changed
        const prevStateQueryParsed = queryString.parse(prevState.query);
        const currStateQueryParsed = queryString.parse(this.state.query);

        let prevStateQuery = {};
        let currStateQuery = {};

        [...Object.keys(this._getSearchParameters()), 'sortField', 'sortOrder'].forEach(key => {
            if (prevStateQueryParsed[key]) {
                prevStateQuery[key] = prevStateQueryParsed[key];
            }
            if (currStateQueryParsed[key]) {
                currStateQuery[key] = currStateQueryParsed[key];
            }
        });

        if (prevState.query === null || queryString.stringify(prevStateQuery) !== queryString.stringify(currStateQuery)) {
            this._getitemsFromServer();
        }


        //This is copied from Fetch to have same refresh functionality
        //Please refactor, if there is another way to put this in it's own component, but how to trigger child 'refresh' function from parent?
        if (prevProps.refreshKey !== this.props.refreshKey) {
            this.setState({
                refreshVersion: this.props.refreshMap[this.props.refreshKey],
            }, this.refresh);

            return;
        }

        if (this.props.refreshMap[this.props.refreshKey] !== this.state.refreshVersion) {
            this.setState({
                refreshVersion: this.props.refreshMap[this.props.refreshKey],
            }, this.refresh);
        }
    }

    _getitemsFromServer() {
        const searchParams = {
            ...this.state.queryParsed,
            itemsPerPage: this.props.itemsPerPage,
            ...this.props.searchParams,
        };

        this.setState({
            items: [],
            nbItems: undefined,
            nbPages: undefined,
            nbPagesLimitReached: false,
            hasMore: undefined,
            isCommunicatingWithServer: true,
            itemKeysChecked: [],
        });

        this.props.fetchFunction(searchParams).then(data => {
            let nbPagesHardLimit;
            if (data.nbItems > this.props.maxItemsForPager) {
                nbPagesHardLimit = Math.floor(this.props.maxItemsForPager / this.props.itemsPerPage);
            }

            this.setState({
                items: data.items,
                nbItems: data.nbItems,
                nbPages: nbPagesHardLimit || data.nbPages,
                nbPagesLimitReached: Boolean(nbPagesHardLimit),
                hasMore: data.hasMore,
                isCommunicatingWithServer: false,
                search: {
                    ...this._getSearchParameters(),
                    ...this.state.queryParsed,
                },
            });
        }).catch(errorMessage => {
            this.setState({
                items: [],
                isCommunicatingWithServer: false,
                error: String(errorMessage),
            });
        });
    }

    refresh() {
        this._getitemsFromServer();
    }

    handleSubmit() {
        this._pushCurrentStateToUrl({page: 0});
    }

    handleReset() {
        this._pushEmptyStateToUrl();
    }

    handleChange(value, attribute) {
        this.setState((prevState, props) => ({
            search: {
                ...prevState.search,
                [attribute]: value,
            },
        }));
    }

    handleCheckAll(checked) {
        if (checked) {
            this.setState({
                itemKeysChecked: this.state.items.filter(this.props.selectableFunction).map(item => item.key),
            });
        } else {
            this.setState({
                itemKeysChecked: [],
            });
        }
    }

    handleCheck(checked, key) {
        if (checked) {
            this.setState((prevState, props) => ({
                itemKeysChecked: [
                    ...prevState.itemKeysChecked,
                    key,
                ],
            }));
        } else {
            this.setState((prevState, props) => ({
                itemKeysChecked: prevState.itemKeysChecked.filter(item => item !== key),
            }));
        }
    }

    handleCheckButton(i) {
        this.setState({
            checkButtonActive: false,
            checkButtonLoading: i,
        });

        const selectableButtonFunction = isArray(this.props.selectableButtonFunction) ? this.props.selectableButtonFunction : [this.props.selectableButtonFunction];

        selectableButtonFunction[i](this.state.itemKeysChecked, this.state.search).then(() => {
            if (!this.props.skipRefreshAfterSelectableButton) {
                this.refresh();
            }

            this.setState({
                checkButtonActive: true,
                checkButtonLoading: -1,
            });
        }).catch(errorMessage => {
            this.setState({
                checkButtonActive: true,
                checkButtonLoading: -1,
                error: String(errorMessage),
            });
        });
    }

    goNextPage() {
        this._pushCurrentStateToUrl({page: +this.state.search.page + 1});
    }

    goPreviousPage() {
        this._pushCurrentStateToUrl({page: +this.state.search.page - 1});
    }

    goPage(key) {
        this._pushCurrentStateToUrl({page: key});
    }

    _pushEmptyStateToUrl() {
        const history = this.context.router.history;
        const location = history.location;

        history.push(location.pathname);
    }

    _pushCurrentStateToUrl(extraChanges) {
        let queryParams = {
            ...this.state.search,
            ...extraChanges,
        };

        queryParams = Object.keys(queryParams)
            .filter(key => queryParams[key]) //filter out empty values
            .filter(key => !(key === 'page' && queryParams[key] === '0')) //filter out page === '0'
            .filter(key => !key.endsWith('_op') || queryParams[key.substring(0, key.length - 3)]) //filter out _op values of empty filters
            .reduce((res, key) => Object.assign(res, {[key]: queryParams[key]}), {});

        const query = queryString.stringify(queryParams);

        const history = this.context.router.history;
        const location = history.location;
        history.push(location.pathname + '?' + query);
    }

    _fetchStateFromUrl() {
        const history = this.context.router.history;
        const location = history.location;

        if (this.state.query !== location.search) {
            this.setState({
                query: location.search,
                queryParsed: queryString.parse(location.search),
            });
        }
    }

    handleDownloadModal(e) {
        e.preventDefault();

        const query = {
            ...this.state.queryParsed,
            ...this.props.searchParams,
        };

        this.props.showModal({
            type: 'download',
            queryString: `?${queryString.stringify(query)}`,
            startDownloadFunction: this.props.downloadModalFunc,
            downloadModalDomainObjectName: this.props.downloadModalDomainObjectName,
        });
    }

    render() {
        let downloadUrl = '';

        const items = this.state.items;

        const history = this.context.router.history;
        const location = history.location;

        if (this.props.downloadUrl) {
            const parsed = {
                ...queryString.parse(location.search),
                ...this.props.searchParams,
                format: 'xlsx',
            };
            downloadUrl = this.props.downloadUrl + '?' + queryString.stringify(parsed);
        }

        if (this.state.isCommunicatingWithServer) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex flex-column justify-content-center">
                                <Loader/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let form = null;
        if (Object.keys(this.props.namedSearchParameters).length > 0 && !this.props.hideFilters && (!this.props.hideFiltersIfEmpty || items.length > 0 || location.search)) {
            form = <Form onSubmit={this.handleSubmit} onReset={this.handleReset} onChange={this.handleChange} search={this.state.search} formElements={this.props.namedSearchParameters}/>;
        }

        let tableColumns = this.props.tableColumns;
        if (this.props.selectableButton) {
            const checkboxColumns = {
                checkbox: {
                    name: (
                        <input
                            type="checkbox"
                            checked={this.state.itemKeysChecked.length > 0 && this.state.itemKeysChecked.length === items.filter(this.props.selectableFunction).length}
                            onChange={event => this.handleCheckAll(event.target.checked)}
                        />
                    ),
                    style: {
                        width: '45px',
                    },
                },
            };
            tableColumns = {...checkboxColumns, ...tableColumns};

            items.forEach(item => {
                item.checkbox = (
                    <PomTooltip tooltip={!isArray(this.props.disabledReasonFunction) ? this.props.disabledReasonFunction(item) : ''}>
                        <input
                            type="checkbox"
                            checked={this.state.itemKeysChecked.includes(item.key)}
                            onChange={event => this.handleCheck(event.target.checked, item.key)}
                            disabled={!this.props.selectableFunction(item)}
                        />
                    </PomTooltip>
                );
            });
        }

        const selectableButtons = isArray(this.props.selectableButton) ? this.props.selectableButton : [this.props.selectableButton].filter(item => item);

        let hidePager = (this.props.hideFilters || this.props.hideFiltersIfEmpty) && items.length === 0;

        return (
            <div>
                {
                    this.props.name &&
                    <div>
                        <div className="font-weight-bold">{this.props.name}:</div>
                    </div>
                }

                {
                    this.state.error &&
                    <div className="alert alert-danger alert-dismissible">
                        {this.state.error}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                }

                {form}

                {items.length > 0 && (
                    <Table
                        columns={tableColumns}
                        items={items}
                        search={this.state.queryParsed}
                        location={location}
                        extraClassName={this.props.extraClassName}
                        autoWidthColumns={this.props.autoWidthColumns}
                        minWidth={this.props.minWidth}
                    />
                )}
                {
                    items.length === 0 &&
                    <div>
                        <small className="font-italic text-muted">(empty)</small>
                    </div>
                }

                {!hidePager && (
                    <Row className="mb-3">
                        <Col xs="auto">
                            {(downloadUrl && (!this.props.downloadUrlMax || this.state.nbItems === undefined || this.state.nbItems < this.props.downloadUrlMax)) && (
                                <Can roles={this.props.downloadUrlRoles}>
                                    <a className="btn btn-link btn-sm" href={downloadUrl}><DesktopDownloadIcon/></a>
                                </Can>
                            )}
                            {this.props.downloadModalFunc && (
                                <Can roles={this.props.downloadUrlRoles}>
                                    <span onClick={this.handleDownloadModal} style={{cursor: 'pointer', marginRight :10}}>
                                        <DesktopDownloadIcon/>
                                    </span>
                                </Can>
                            )}
                            {this.state.nbItems !== undefined && <>Results: {this.state.nbItems}</>}
                        </Col>
                        <Col>
                            {this.state.nbPages > 1 && (
                                <Pager
                                    maxPagesToShow={20}
                                    currentPage={+this.state.search.page}
                                    nbPages={this.state.nbPages}
                                    nbPagesLimitReached={this.state.nbPagesLimitReached}
                                    goNextPage={this.goNextPage}
                                    goPreviousPage={this.goPreviousPage}
                                    goPage={this.goPage}
                                />)
                            }
                            {
                                !this.state.nbPages && this.state.hasMore !== undefined &&
                                <SimplePager currentPage={+this.state.search.page} hasMore={this.state.hasMore} goNextPage={this.goNextPage} goPreviousPage={this.goPreviousPage}/>
                            }
                        </Col>
                    </Row>
                )}

                {
                    items.length > 0 && selectableButtons.length > 0 &&
                    <Row className="mb-3">
                        {
                            selectableButtons.map((selectableButton, i) => {
                                const disabledReasonFunction = isArray(this.props.disabledReasonFunction) ? this.props.disabledReasonFunction : [this.props.disabledReasonFunction];
                                const disabledReason = this.state.items
                                    .filter(item => this.state.itemKeysChecked.includes(item.key))
                                    .map(item => disabledReasonFunction[i](item))
                                    .filter(reason => reason)
                                    .map((reason, i) => (
                                        <li key={i} style={{maxWidth: 260}}>{reason}</li>
                                    ));

                                return (
                                    <Col key={i} xs="auto">
                                        <div className="d-flex align-items-center">
                                            <Button
                                                color="success"
                                                disabled={
                                                    !this.state.checkButtonActive ||
                                                    this.state.itemKeysChecked.length === 0 ||
                                                    disabledReason.length > 0
                                                }
                                                onClick={() => this.handleCheckButton(i)}
                                            >
                                                {selectableButton}
                                            </Button>
                                            {i === this.state.checkButtonLoading && <div className="ml-1" style={{width: '100px'}}><Loader color="danger"/></div>}
                                        </div>
                                        {
                                            disabledReason.length > 0 &&
                                            <Fragment>
                                                <div style={{textAlign: 'center'}}><IssueOpenedIcon style={{fill: 'red'}}/></div>
                                                <ul>{disabledReason}</ul>
                                            </Fragment>
                                        }
                                    </Col>
                                );
                            })
                        }
                    </Row>
                }

            </div>
        );
    }
}


AbstractItemsTableWithLoader.propTypes = {
    tableColumns: PropTypes.object.isRequired,
    fetchFunction: PropTypes.func.isRequired,
    extraClassName: PropTypes.string,
    autoWidthColumns: PropTypes.array,

    namedSearchParameters: PropTypes.object,
    itemsPerPage: PropTypes.number,
    maxItemsForPager: PropTypes.number,
    downloadUrl: PropTypes.string,
    searchParams: PropTypes.object,
    hideFilters: PropTypes.bool,
    hideFiltersIfEmpty: PropTypes.bool,
    name: PropTypes.string,
    downloadUrlMax: PropTypes.number,

    selectableButton: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    selectableFunction: PropTypes.func,
    disabledReasonFunction: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),

    selectableButtonFunction: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    skipRefreshAfterSelectableButton: PropTypes.bool,

    //This is copied from Fetch to have same refresh functionality
    refreshKey: PropTypes.string,
    minWidth: PropTypes.number,
};

AbstractItemsTableWithLoader.defaultProps = {
    namedSearchParameters: {},
    selectableFunction: () => true,
    disabledReasonFunction: () => '',
};

AbstractItemsTableWithLoader.contextTypes = {
    router: PropTypes.shape({
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
            replace: PropTypes.func.isRequired,
            createHref: PropTypes.func.isRequired,
            location: PropTypes.shape({
                search: PropTypes.string.isRequired,
                pathname: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired
};

export default withRefresh(AbstractItemsTableWithLoader);


