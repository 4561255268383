import React, {useContext} from 'react';
import {Role} from "./BackOfficeUserRole";
import {AppContext} from "../AppContext";

type Props = {
    roles: Role[];
    children: any;
};

const Can = ({
                 roles,
                 children,
             }: Props) => {
    const {currentUser} = useContext(AppContext);

    //If component doesn't need permissions, show the component
    if (roles === undefined) {
        return <>{children}</>;
    }

    const userRoles: Role[] | undefined = currentUser?.extraSettings.roles;

    //If user doesn't have permissions, show nothing
    if (userRoles === undefined) {
        return null;
    }

    const canShow = userRoles.reduce((acc, role) => {
        return acc || roles.includes(role);
    }, false);

    if (canShow) {
        return <>{children}</>;
    } else {
        return null;
    }
};

export default Can;
