import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Fetch from "../../../Elements/Fetch";
import FormGroupRowDate from "../../../Elements/FormGroupRowDate";
import {fetchSendersList, validEmail} from "../../../services";
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import FormGroupRowSelect from "../../../Elements/FormGroupRowSelect";
import FormWithActions from "../../../Elements/FormWithActions/FormWithActions";
import FormGroupRowSender from "../../../Elements/FormGroupRowSender";
import {Col, Row, Label} from "reactstrap";
import './BillingSenderForm.css';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import FormGroupRowCheckbox from "../../../Elements/FormGroupRowCheckbox";
import FetchFromContext from "../../../Elements/FetchFromContext";
import BillingSenderContactForm from "./BiilingSenderContactForm";
import FormGroupRowTextList from "../../../Elements/FormGroupRowTextList";

class BillingSenderForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contact: {language: 'nl'},
            name: (this.props.billingSender && this.props.billingSender.name) ? this.props.billingSender.name : '',
            billableFrom: (this.props.billingSender && this.props.billingSender.billableFrom) ? new Date(this.props.billingSender.billableFrom).toISOString().slice(0, 10) : '',
            senderIds: (this.props.billingSender && this.props.billingSender.senders) ? this.props.billingSender.senders.map(sender => sender.id) : [],
            billingEventTypes: (this.props.billingSender && this.props.billingSender.billingEventTypes) ? this.props.billingSender.billingEventTypes : (this.props.billingEventTypes || []),
            prepaidBillingEventTypes: (this.props.billingSender && this.props.billingSender.prepaidBillingEventTypes) ? this.props.billingSender.prepaidBillingEventTypes : (this.props.billingEventTypes || []),
            monthlyPlanBillingEventTypes: (this.props.billingSender && this.props.billingSender.monthlyPlanBillingEventTypes) ? this.props.billingSender.monthlyPlanBillingEventTypes : (this.props.billingEventTypes || []),
            billingEventSubtypes: (this.props.billingSender && this.props.billingSender.billingEventSubtypes) ? this.props.billingSender.billingEventSubtypes : (this.props.billingEventSubtypes || []),
            prepaidBillingEventSubtypes: (this.props.billingSender && this.props.billingSender.prepaidBillingEventSubtypes) ? this.props.billingSender.prepaidBillingEventSubtypes : (this.props.billingEventSubtypes || []),
            monthlyPlanBillingEventSubtypes: (this.props.billingSender && this.props.billingSender.monthlyPlanBillingEventSubtypes) ? this.props.billingSender.monthlyPlanBillingEventSubtypes : (this.props.billingEventSubtypes || []),
            externalClientId: (this.props.billingSender && this.props.billingSender.externalClientId) ? this.props.billingSender.externalClientId : '',
            taxRate: (this.props.billingSender && this.props.billingSender.taxRate) ? this.props.billingSender.taxRate : '',
            billingResellerId: (this.props.billingSender && this.props.billingSender.billingReseller) ? this.props.billingSender.billingReseller.id : '',
            billingResellerType: (this.props.billingSender && this.props.billingSender.billingResellerType) ? this.props.billingSender.billingResellerType : '',
            billingResellerCommission: (this.props.billingSender && this.props.billingSender.billingResellerCommission) ? this.props.billingSender.billingResellerCommission : '',
            datylonTemplate: (this.props.billingSender && this.props.billingSender.datylonTemplate) ? this.props.billingSender.datylonTemplate : '',
            createSendReports: this.props.billingSender ? this.props.billingSender.createSendReports : false,
            isFreeTier: this.props.billingSender ? this.props.billingSender.isFreeTier : false,
            inactive: this.props.billingSender ? this.props.billingSender.inactive : false,
            contactEmailAddresses: (this.props.billingSender && this.props.billingSender.contactEmailAddresses) ? this.props.billingSender.contactEmailAddresses : [],
            mtpId: (this.props.billingSender && this.props.billingSender.mtpId) ? this.props.billingSender.mtpId : '',
            poNumber: (this.props.billingSender && this.props.billingSender.poNumber) ? this.props.billingSender.poNumber : '',
            portalLogo: (this.props.billingSender && this.props.billingSender.portalLogo) ? this.props.billingSender.portalLogo : '',
            monthlyPlanNbCredits: (this.props.billingSender && this.props.billingSender.monthlyPlanNbCredits) ? this.props.billingSender.monthlyPlanNbCredits : '',
            monthlyPlanStatusPeriodNbMonths: (this.props.billingSender && this.props.billingSender.monthlyPlanStatusPeriodNbMonths) ? this.props.billingSender.monthlyPlanStatusPeriodNbMonths : '',
            typeContractPOM: (this.props.billingSender && this.props.billingSender.typeContractPOM) ? this.props.billingSender.typeContractPOM : '',
            slaSigned: (this.props.billingSender && this.props.billingSender.slaSigned) ? this.props.billingSender.slaSigned : '',
            dataProcessingAgreement: (this.props.billingSender && this.props.billingSender.dataProcessingAgreement) ? this.props.billingSender.dataProcessingAgreement : '',
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handleSendersChange = this.handleSendersChange.bind(this);
    }

    handleAction(action) {
        return this.props.action(action, this.state);
    }

    handleChange(attribute) {
        return (value) => {
            this.setState({
                [attribute]: value,
            });
        };
    }

    handleSendersChange(senderList) {
        return (senderIds) => {
            if (!this.state.name && senderIds[0]) {
                this.setState({
                    name: senderList.filter(sender => sender.id === senderIds[0])[0].name,
                });
            }

            this.setState({
                senderIds: senderIds,
            });
        };
    }

    updateBillingContact(newData) {
        this.setState({
            contact: {
                ...this.state.contact,
                ...newData,
            }
        });
    }

    hasContact() {
        return Object.keys(this.state.contact).length > 1;
    }

    render() {

        const propertyNames = {
            senderIds: 'Senders',
            name: 'Billing name',
            billableFrom: 'Billable from',
            billingEventSubtypes: 'Billable subtypes postpaid',
            prepaidBillingEventSubtypes: 'Billable subtypes prepaid',
            monthlyPlanBillingEventSubtypes: 'Billable subtypes monthly plan',
            externalClientId: 'Eenvoudig Factureren client id',
            taxRate: 'Tax',
            billingResellerId: 'Billing reseller',
            billingResellerType: 'Billing Reseller Type',
            billingResellerCommission: 'Billing Reseller Commission',
            datylonTemplate: 'Datylon template',
            mtpId: 'Mail to Pay Id',
            poNumber: 'PO number',
            portalLogo: 'Portal logo',
            monthlyPlanNbCredits: 'Monthly plan nb credits',
            monthlyPlanStatusPeriodNbMonths: 'Monthly plan status period nb months',
            typeContractPOM: 'Contract POM',
            slaSigned: 'SLA getekend',
            dataProcessingAgreement: 'Data processing agreement',
            contactEmail: 'Contact email',
            contactLanguage: 'Contact language',
            contactPointType: 'Contact point type',
            sectorType: 'Contact sector type'
        };

        const requiredProperties = [];
        if (!this.state.name) {
            requiredProperties.push('name');
        }
        if (!this.state.billableFrom) {
            requiredProperties.push('billableFrom');
        }

        let contact = this.state.contact;

        if (this.hasContact() && !contact.email) {
            requiredProperties.push('contactEmail');
        }

        if (this.hasContact() && !contact.language) {
            requiredProperties.push('contactLanguage');
        }
        if (this.hasContact() && !contact.sectorType) {
            requiredProperties.push('sectorType');
        }

        if (this.hasContact() && !contact.contactPointType) {
            requiredProperties.push('contactPointType');
        }

        let disableSubmitReason = '';
        if (requiredProperties.length > 0) {
            disableSubmitReason = `The following properties are required: ${requiredProperties.map(p => propertyNames[p]).join(', ')}`;
        }

        if (this.state.contactEmailAddresses.some(email => !validEmail(email))) {
            disableSubmitReason = 'All billing sender email addresses need to be valid.';
        }

        if (this.hasContact() && !validEmail(contact.email)) {
            disableSubmitReason = 'Contact email needs to be valid.'
        }

        return (
            <FormWithActions
                actions={this.props.actions}
                onAction={this.handleAction}
                disableSubmitReason={disableSubmitReason}
            >
                <Fetch
                    fetchFunction={fetchSendersList}
                    fetchFunctionParams={{billingSenderId: (this.props.billingSender && this.props.billingSender.id) ? this.props.billingSender.id : ''}}
                    className="form-group"
                >
                    {senderList => (
                        <FormGroupRowSender
                            label={propertyNames.senderIds}
                            value={this.state.senderIds}
                            multi
                            onChange={this.handleSendersChange(senderList)}
                            senders={senderList}
                        />
                    )}
                </Fetch>

                <FormGroupRowText
                    label={propertyNames.name}
                    value={this.state.name}
                    onChange={this.handleChange('name')}
                />

                <FormGroupRowDate
                    label={propertyNames.billableFrom}
                    value={this.state.billableFrom}
                    onChange={this.handleChange('billableFrom')}
                    description="Sender is billable from 00:00:00 of this date. This must be the first day of the month."
                />

                <Row form>
                    <Col xs="3"/>
                    <Col style={{textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>
                        Prepaid
                    </Col>
                    <Col style={{textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>
                        Postpaid
                    </Col>
                    <Col style={{textAlign: 'center', marginBottom: 5, fontWeight: 'bold'}}>
                        Monthly plan
                    </Col>
                </Row>

                <FetchFromContext property="billingEventTypes" className="form-group">
                    {billingEventTypes => {
                        const options = billingEventTypes.filter(it => it !== 'OPTIN');

                        return (
                            <Row form className="form-group">
                                <Col xs="3">
                                    <Label className="col-form-label">Billable Types</Label>
                                </Col>
                                <Col className="billing-sender-form-events-select">
                                    <Select
                                        value={this.state.prepaidBillingEventTypes}
                                        multi
                                        onChange={selection => this.handleChange('prepaidBillingEventTypes')(selection.map(item => item.value))}
                                        options={options.map(type => ({value: type, label: type}))}
                                        clearable={false}
                                    />
                                </Col>
                                <Col className="billing-sender-form-events-select">
                                    <Select
                                        value={this.state.billingEventTypes}
                                        multi
                                        onChange={selection => this.handleChange('billingEventTypes')(selection.map(item => item.value))}
                                        options={options.map(type => ({value: type, label: type}))}
                                        clearable={false}
                                    />
                                </Col>
                                <Col className="billing-sender-form-events-select">
                                    <Select
                                        value={this.state.monthlyPlanBillingEventTypes}
                                        multi
                                        onChange={selection => this.handleChange('monthlyPlanBillingEventTypes')(selection.map(item => item.value))}
                                        options={options.map(type => ({value: type, label: type}))}
                                        clearable={false}
                                    />
                                </Col>
                            </Row>
                        );
                    }}
                </FetchFromContext>

                <FetchFromContext property="billingEventSubtypes" className="form-group">
                    {billingEventSubtypes => {
                        const options = billingEventSubtypes.filter(it => it !== 'OPTIN_PAID_DELIVERED');

                        return (
                            <Row form className="form-group">
                                <Col xs="3">
                                    <Label className="col-form-label">Billable subtypes</Label>
                                </Col>
                                <Col className="billing-sender-form-events-select">
                                    <Select
                                        value={this.state.prepaidBillingEventSubtypes}
                                        multi
                                        onChange={selection => this.handleChange('prepaidBillingEventSubtypes')(selection.map(item => item.value))}
                                        options={options.map(subType => ({value: subType, label: subType}))}
                                        clearable={false}
                                    />
                                </Col>
                                <Col className="billing-sender-form-events-select">
                                    <Select
                                        value={this.state.billingEventSubtypes}
                                        multi
                                        onChange={selection => this.handleChange('billingEventSubtypes')(selection.map(item => item.value))}
                                        options={options.map(subType => ({value: subType, label: subType}))}
                                        clearable={false}
                                    />
                                </Col>
                                <Col className="billing-sender-form-events-select">
                                    <Select
                                        value={this.state.monthlyPlanBillingEventSubtypes}
                                        multi
                                        onChange={selection => this.handleChange('monthlyPlanBillingEventSubtypes')(selection.map(item => item.value))}
                                        options={options.map(subType => ({value: subType, label: subType}))}
                                        clearable={false}
                                    />
                                </Col>
                            </Row>
                        )
                    }}
                </FetchFromContext>

                <FormGroupRowSelect
                    label={propertyNames.monthlyPlanNbCredits}
                    value={this.state.monthlyPlanNbCredits}
                    onChange={this.handleChange('monthlyPlanNbCredits')}
                    addEmptyOption
                    options={[
                        {value: '78', label: '78 (€9.99)'},
                        {value: '102', label: '102 (€12.99)'},
                        {value: '132', label: '132 (€16.99)'},
                        {value: '174', label: '174 (€21.99)'},
                        {value: '231', label: '231 (€28.99)'},
                        {value: '306', label: '306 (€37.99)'},
                        {value: '396', label: '396 (€48.99)'},
                        {value: '516', label: '516 (€62.99)'},
                        {value: '678', label: '678 (€81.99)'},
                        {value: '882', label: '882 (€105.99)'},
                        {value: '1161', label: '1161 (€137.99)'},
                        {value: '1521', label: '1521 (€178.99)'},
                        {value: '1998', label: '1998 (€232.99)'},
                        {value: '2631', label: '2631 (€303.99)'},
                        {value: '3435', label: '3435 (€392.99)'},
                        {value: '4413', label: '4413 (€499.99)'},
                        {value: '5793', label: '5793 (€649,99)'},
                        {value: '7605', label: '7605 (€844,99)'},
                        {value: '9990', label: '9990 (€1.098,99)'},
                        {value: '13122', label: '13122 (€1.428,99)'},
                        {value: '17238', label: '17238 (€1.857,99)'},
                        {value: '22650', label: '22650 (€2.415,99)'},
                        {value: '30315', label: '30315 (€3.199,99)'},
                        {value: '39876', label: '39876 (€4.164,99)'},
                        {value: '52452', label: '52452 (€5.419,99)'},
                        {value: '68919', label: '68919 (€7.044,99)'},
                    ]}
                />

                <FormGroupRowText
                    label={propertyNames.monthlyPlanStatusPeriodNbMonths}
                    value={this.state.monthlyPlanStatusPeriodNbMonths}
                    onChange={this.handleChange('monthlyPlanStatusPeriodNbMonths')}
                />

                <FormGroupRowText
                    label={propertyNames.externalClientId}
                    value={this.state.externalClientId}
                    onChange={this.handleChange('externalClientId')}
                />

                <FormGroupRowText
                    label={propertyNames.mtpId}
                    value={this.state.mtpId}
                    onChange={this.handleChange('mtpId')}
                />

                <FormGroupRowText
                    label={propertyNames.poNumber}
                    value={this.state.poNumber}
                    onChange={this.handleChange('poNumber')}
                />

                <FormGroupRowSelect
                    label={propertyNames.taxRate}
                    value={this.state.taxRate}
                    onChange={this.handleChange('taxRate')}
                    addEmptyOption
                    options={[
                        {value: 'TAX_INCLUSIVE', label: 'TAX_INCLUSIVE'},
                        {value: 'TAX_EXCLUSIVE', label: 'TAX_EXCLUSIVE'},
                    ]}
                />

                <FetchFromContext property="billingResellers" className="form-group">
                    {billingResellers => (
                        <FormGroupRowSelect
                            label={propertyNames.billingResellerId}
                            value={this.state.billingResellerId}
                            onChange={this.handleChange('billingResellerId')}
                            addEmptyOption
                            options={billingResellers.map(billingReseller => ({
                                value: billingReseller.id,
                                label: billingReseller.name
                            }))}
                        />
                    )}
                </FetchFromContext>

                <FormGroupRowSelect
                    label={propertyNames.billingResellerType}
                    value={this.state.billingResellerType}
                    onChange={this.handleChange('billingResellerType')}
                    addEmptyOption
                    options={[
                        {value: 'DIRECT_BILLING', label: 'DIRECT_BILLING'},
                        {value: 'BILLING_RESELLER', label: 'BILLING_RESELLER'},
                    ]}
                />

                <FormGroupRowText
                    label={propertyNames.billingResellerCommission}
                    value={this.state.billingResellerCommission}
                    onChange={this.handleChange('billingResellerCommission')}
                    description="(e.g. 50.00%, 0.01%, ...)"
                />

                <FormGroupRowSelect
                    label={propertyNames.datylonTemplate}
                    value={this.state.datylonTemplate}
                    onChange={this.handleChange('datylonTemplate')}
                    emptyOptionLabel="Default"
                    options={[
                        {value: 'WITHOUT_THIRD_GRAPH_PAYCONIQ', label: 'Default (Payconiq invoices)'},
                        {value: 'WITH_DOC_DELIVERY_GRAPH', label: 'Default + document delivery'},
                        {value: 'WITH_FIRST_USER_GRAPH', label: 'Default + first users'},
                        {value: 'WITH_FIRST_USER_GRAPH_PAYCONIQ', label: 'Default + first users (Payconiq invoices)'},
                        {value: 'WITHOUT_CREDITS_GRAPH', label: 'Default - credits'},
                        {value: 'WITHOUT_CREDITS_GRAPH_PAYCONIQ', label: 'Default - credits (Payconiq invoices)'},
                    ]}
                />

                <FormGroupRowCheckbox
                    label="Create and send reports"
                    checked={this.state.createSendReports}
                    onChange={this.handleChange('createSendReports')}
                />

                <FormGroupRowCheckbox
                    label="Is free tier"
                    checked={this.state.isFreeTier}
                    onChange={this.handleChange('isFreeTier')}
                />

                <FormGroupRowCheckbox
                    label="Inactive"
                    checked={this.state.inactive}
                    onChange={this.handleChange('inactive')}
                />

                <FormGroupRowTextList
                    label="Contact email addresses"
                    value={this.state.contactEmailAddresses}
                    onChange={this.handleChange('contactEmailAddresses')}
                />

                {!this.props.billingSender &&
                    <>
                        <label className='col-form-label'>Contact:</label>
                        <div className='ml-4 my-4'>
                            <BillingSenderContactForm contact={this.state.contact}
                                                      onChange={this.updateBillingContact.bind(this)}/>
                        </div>
                    </>
                }

                <FormGroupRowText
                    label={propertyNames.portalLogo}
                    value={this.state.portalLogo}
                    onChange={this.handleChange('portalLogo')}
                />

                <FormGroupRowSelect
                    label={propertyNames.typeContractPOM}
                    value={this.state.typeContractPOM}
                    onChange={this.handleChange('typeContractPOM')}
                    options={[
                        {value: 'Geen contract', label: 'Geen contract'},
                        {value: 'POM sender agreement', label: 'POM sender agreement'},
                        {value: 'Getekende offerte', label: 'Getekende offerte'},
                        {value: 'Custom contract', label: 'Custom contract'},
                        {value: 'Onboarding direct', label: 'Onboarding direct'},
                        {value: 'Onboarding via partner', label: 'Onboarding via partner'},
                        {value: 'Contract via partner', label: 'Contract via partner'},
                        {value: 'POM order form', label: 'POM order form'},
                    ]}
                />

                <FormGroupRowSelect
                    label={propertyNames.slaSigned}
                    value={this.state.slaSigned}
                    onChange={this.handleChange('slaSigned')}
                    options={[
                        {value: 'Ja', label: 'Ja'},
                        {value: 'Nee', label: 'Nee'},
                    ]}
                />

                <FormGroupRowSelect
                    label={propertyNames.dataProcessingAgreement}
                    value={this.state.dataProcessingAgreement}
                    onChange={this.handleChange('dataProcessingAgreement')}
                    options={[
                        {value: 'Geen', label: 'Geen'},
                        {value: 'Onderdeel van de algemene voorwaarden', label: 'Onderdeel van de algemene voorwaarden'},
                        {value: 'Custom contract', label: 'Custom contract'},
                    ]}
                />
            </FormWithActions>
        );
    }
}

BillingSenderForm.propTypes = {
    action: PropTypes.func.isRequired,
    actions: PropTypes.array,

    billingSender: PropTypes.shape({
        name: PropTypes.string.isRequired,
        billableFrom: PropTypes.string.isRequired,
        senders: PropTypes.array.isRequired,
        billingEventSubtypes: PropTypes.array.isRequired,
        prepaidBillingEventSubtypes: PropTypes.array.isRequired,
        monthlyPlanBillingEventSubtypes: PropTypes.array.isRequired,
        monthlyPlanNbCredits: PropTypes.string,
    }),

    billingEventTypes: PropTypes.array,
    billingEventSubtypes: PropTypes.array,
};

BillingSenderForm.defaultProps = {
    actions: [],
};

export default BillingSenderForm;
