import RavenJS from 'raven-js';

type AuthPlatform = 'twoFactor' | 'google';

export const authenticate = (partialBody: object, platform: AuthPlatform) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + `/users/session/${platform}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            expiresIn: "36000000",
            platform: "Web",
            appName: "BackOffice",
            environmentId: "N/A",
            ...partialBody,
        })
    }).then(response => {
        return response.json().then(data => {
            if (response.status !== 201) {
                if (data) {
                    return Promise.reject(data.message);
                } else {
                    return Promise.reject(response.status + ': ' + response.statusText);
                }
            }
        });
    }).catch(errorMessage => {
        RavenJS.captureException(new Error(errorMessage));
        return Promise.reject(errorMessage);
    });
};

